var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.operatoria !== 16)?_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(parseInt(_vm.estado) !== 12),expression:"parseInt(estado) !== 12"}],staticClass:"mr-5",attrs:{"color":"info"},on:{"click":function($event){return _vm.registrosLegajo()}}},'v-btn',attrs,false),on),[_vm._v(" Editar ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.success)?_c('v-alert',{attrs:{"border":"bottom","color":"success","dark":""}},[_vm._v(" "+_vm._s(_vm.successMensaje)+" ")]):_vm._e(),(_vm.error)?_c('v-alert',{attrs:{"border":"bottom","color":"error","dark":""}},[_vm._v(" "+_vm._s(_vm.successError)+" ")]):_vm._e(),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.operatoria !== 6),expression:"operatoria !== 6"}]},[_c('v-row',[_c('v-col',[_c('v-card-title',[_vm._v("Parametros:")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Cuil / Cuit del Beneficiario","type":"number","pattern":"/^[0-9]{11}$/","rules":[
              function (v) { return (v !== undefined && /^[0-9]{11}$/.test(v)) ||
                'El CUIT no tiene un formato válido'; },
              function (v) { return (v !== undefined && v.length <= 11) ||
                'El CUIT no debe tener más de 11 caracteres'; } ]},model:{value:(_vm.cuit),callback:function ($$v) {_vm.cuit=$$v},expression:"cuit"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Dni","type":"number","pattern":"/^[0-9]{8}$/","rules":[
              function (v) { return /^[0-9]{8}$/.test(v) || 'El DNI no tiene un formato válido'; } ]},model:{value:(_vm.dni),callback:function ($$v) {_vm.dni=$$v},expression:"dni"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"CBU","type":"number","pattern":"/^[0-9]{22}$/","rules":[
              function (v) { return /^[0-9]{22}$/.test(v) || 'El CBU debe tener 22 caracteres'; } ]},model:{value:(_vm.cbu),callback:function ($$v) {_vm.cbu=$$v},expression:"cbu"}})],1)],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Email","rules":[
            function (v) { return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v) ||
              'El email no tiene un formato válido'; } ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Movil"},model:{value:(_vm.celular),callback:function ($$v) {_vm.celular=$$v},expression:"celular"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!/[0-9]{11}$/.test(_vm.cuit) ||
              _vm.cuit.length > 11 ||
              !/[0-9]{22}$/.test(_vm.cbu) ||
              _vm.cbu.length > 22},on:{"click":_vm.guardarRegistrosLegajo}},[_vm._v(" Actualizar ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.salir}},[_vm._v(" Cancelar ")])],1)],1)],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(_vm.operatoria === 6),expression:"operatoria === 6"}]},[_c('v-row',[_c('v-col',[_c('v-card-title',[_vm._v("Parametros Mendoza Activa:")])],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"disabled":_vm.linea.id === 132,"label":"Cuil / Cuit del Beneficiario","type":"number","pattern":"/^[0-9]{11}$/","rules":[
              function (v) { return /^[0-9]{11}$/.test(v) || 'El CUIT no tiene un formato válido'; } ]},model:{value:(_vm.cbuToDisplay),callback:function ($$v) {_vm.cbuToDisplay=$$v},expression:"cbuToDisplay"}})],1),_c('v-col',[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.linea.id !== 132),expression:"linea.id !== 132"}],attrs:{"label":"Numero Ticket","type":"number","pattern":"/^[0-9]{1,12}$/"},model:{value:(_vm.ticket),callback:function ($$v) {_vm.ticket=$$v},expression:"ticket"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"CBU","type":"string","pattern":"/^[0-9]{22}$/","rules":[
              function (v) { return /^[0-9]{22}$/.test(v) || 'El CBU debe tener 22 caracteres'; } ]},model:{value:(_vm.cuitCbu.cbu),callback:function ($$v) {_vm.$set(_vm.cuitCbu, "cbu", $$v)},expression:"cuitCbu.cbu"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field-percent',{attrs:{"label":"Monto ANR Aprobado","properties":{
              prefix: '$',
              readonly: false,
              outlined: false,
              clearable: true,
              placeholder: '',
            },"options":{
              locale: 'es-AR',
              length: 15,
              precision: 2,
              empty: null,
            }},model:{value:(_vm.formatMontoANR),callback:function ($$v) {_vm.formatMontoANR=$$v},expression:"formatMontoANR"}})],1),_c('v-col',[_c('v-text-field-percent',{attrs:{"label":"Inversión Aprobada","properties":{
              prefix: '$',
              readonly: false,
              outlined: false,
              clearable: true,
              placeholder: '',
            },"options":{
              locale: 'es-AR',
              length: 15,
              precision: 2,
              empty: null,
            }},model:{value:(_vm.formatInversionAprobada),callback:function ($$v) {_vm.formatInversionAprobada=$$v},expression:"formatInversionAprobada"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}}),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!/^[0-9]{22}$/.test(_vm.cuitCbu.cbu)},on:{"click":_vm.guardarRegistrosLegajo}},[_vm._v(" Actualizar ")])],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.salir}},[_vm._v(" Cancelar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }