<template>
  <div>
    <ListadoComprobantes
      :verFlag="true"
      :comprobantes="comprobantes"
      v-if="mostrarComprobantes"
    ></ListadoComprobantes>
    <v-simple-table>
      <template v-slot:default v-if="!mostrarComprobantes">
        <thead>
          <tr>
            <th class="text-left">Fecha</th>
            <th class="text-left">Cant. de comprobantes</th>
            <th class="text-left">Total Neto</th>
            <th class="text-left">Total</th>
            <th class="text-left">Reintegro Billetera</th>
            <th class="text-left">Reintegro Efectivo</th>
            <th class="text-left">Reintegro Credito Fiscal</th>
            <th class="text-left">Reintegro Total</th>
            <th class="text-left">Opciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="liquidaciones.length == 0">
            <td class="text-center" colspan="8">No hay liquidaciones aun</td>
          </tr>
          <tr v-for="item in liquidaciones" :key="item.id">
            <td>{{ formatDate(item.createdAt) }}</td>
            <td>{{ item.comprobantes.length }}</td>
            <td>$ {{ formatMonto(item.total_iva.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(item.total.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(item.billetera.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(item.efectivo.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(item.credito_fiscal.toFixed(2)) }}</td>
            <td>
              $
              {{
                formatMonto(
                  (
                    item.billetera +
                    item.efectivo +
                    item.credito_fiscal
                  ).toFixed(2)
                )
              }}
            </td>
            <td>
              <span v-if="!item.anticipo">
                <v-btn color="info" @click="verComprobantes(item.comprobantes)"
                  >Ver Comprobantes</v-btn
                >
              </span>
              <span v-if="item.anticipo">
                <v-alert type="info">ANTICIPO</v-alert>
              </span>
            </td>
          </tr>
          <tr class="highlight">
            <td colspan="1"></td>

            <td>Totales:</td>
            <td>$ {{ formatMonto(totalNeto.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(total.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(totalBilletera.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(totalEfectivo.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(totalCreditoFiscal.toFixed(2)) }}</td>
            <td>$ {{ formatMonto(totalReintegro.toFixed(2)) }}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="9"></td>
          </tr>
          <tr>
            <td colspan="1"></td>
            <td class="highlight">Inversión Aprobada:</td>
            <td class="highlight">$ {{ formatMonto(inversionAprobada) }}</td>

            <td colspan="3"></td>
            <td class="highlight">Monto ANR Aprobado:</td>
            <td class="highlight">$ {{ formatMonto(montoANRAprobado) }}</td>
            <td></td>
          </tr>
          <tr>
            <td colspan="1"></td>
            <td class="highlight">Total Neto:</td>
            <td class="highlight">${{ formatMonto(totalNeto.toFixed(2)) }}</td>

            <td colspan="3"></td>
            <td class="highlight">Total Reintegrado:</td>
            <td class="highlight">
              ${{ formatMonto(totalReintegro.toFixed(2)) }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td colspan="1"></td>
            <td class="highlight">Inversión Pendiente:</td>
            <td class="highlight">$ {{ formatMonto(montoPendiente) }}</td>

            <td colspan="3"></td>
            <td class="highlight">Saldo Pendiente:</td>
            <td class="highlight">$ {{ formatMonto(saldoPendiente) }}</td>
            <td></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-btn
      color="primary"
      class="mt-5 ml-5 mr-5 mb-5"
      block
      v-if="mostrarComprobantes"
      @click="mostrarComprobantes = false"
    >
      Volver al listado
    </v-btn>
  </div>
</template>

<script>
import moment from 'moment';
import ListadoComprobantes from '@/components/Comprobantes/ListadoComprobantes.vue';
import { formatMonto } from '../../common/util';

export default {
  props: ['liquidaciones', 'clienteParametro'],
  data: () => ({
    mostrarComprobantes: false,
    comprobantes: [],
  }),
  created() {
    this.formatMonto = formatMonto;
  },
  components: {
    ListadoComprobantes,
  },
  computed: {
    totalNeto() {
      return this.liquidaciones.reduce((acc, item) => acc + item.total_iva, 0);
    },
    total() {
      return this.liquidaciones.reduce((acc, item) => acc + item.total, 0);
    },
    totalBilletera() {
      return this.liquidaciones.reduce((acc, item) => acc + item.billetera, 0);
    },
    totalEfectivo() {
      return this.liquidaciones.reduce((acc, item) => acc + item.efectivo, 0);
    },
    totalCreditoFiscal() {
      return this.liquidaciones.reduce(
        (acc, item) => acc + item.credito_fiscal,
        0
      );
    },
    totalReintegro() {
      return this.liquidaciones.reduce(
        (acc, item) =>
          acc + (item.billetera + item.efectivo + item.credito_fiscal),
        0
      );
    },
    inversionAprobada() {
      if (this.clienteParametro.length > 0) {
        const parametro = this.clienteParametro.find(
          (param) => param.tipo_parametro_id.clave === 'liquidacion_aprobada'
        );
        return parametro ? parametro.valor : '0,00';
      } else {
        return '0,00';
      }
    },
    montoANRAprobado() {
      if (this.clienteParametro.length > 0) {
        const parametro = this.clienteParametro.find(
          (param) => param.tipo_parametro_id.clave === 'monto_desembolso'
        );
        return parametro ? parametro.valor : '0,00';
      } else {
        return '0,00';
      }
    },
    montoPendiente() {
      if (this.inversionAprobada) {
        return (
          parseFloat(this.inversionAprobada) - parseFloat(this.totalNeto)
        ).toFixed(2);
      } else {
        return '0,00';
      }
    },
    saldoPendiente() {
      if (this.montoANRAprobado) {
        return (
          parseFloat(this.montoANRAprobado) - parseFloat(this.totalReintegro)
        ).toFixed(2);
      } else {
        return '0,00';
      }
    },
  },
  methods: {
    verComprobantes(comp) {
      this.mostrarComprobantes = true;
      this.comprobantes = comp.map((c) => c.comprobante_id);
    },
    formatDate(f) {
      return f ? moment(f).format('DD-MM-YYYY') : '';
    },
  },
};
</script>
<style>
.highlight {
  background-color: #f0f0f0;
  font-weight: bold;
}
</style>
