var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(!_vm.verFlag)?_c('th',[_c('v-checkbox',{on:{"click":function($event){return _vm.selAll(_vm.comprobante)}}})],1):_vm._e(),_c('th',{staticClass:"text-left"},[_vm._v("Tipo")]),_c('th',{staticClass:"text-left"},[_vm._v("N° Comprobante")]),_c('th',{staticClass:"text-left"},[_vm._v("Concepto")]),_c('th',{staticClass:"text-left"},[_vm._v("Total Neto")]),_c('th',{staticClass:"text-left"},[_vm._v("Total")]),_c('th',{staticClass:"text-left"},[_vm._v("Opciones")])])]),_c('tbody',[(_vm.comprobantes.length == 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"6"}},[_vm._v("No hay documentos cargados")])]):_vm._e(),_vm._l((_vm.comprobante),function(item){return _c('tr',{key:item.id,ref:("r" + (item.id)),refInFor:true},[(!_vm.verFlag)?_c('td',[(!item.rechazado && item.tipo_comprobante)?_c('v-checkbox',{on:{"click":function($event){return _vm.selOne(_vm.comprobante)}},model:{value:(item.sel),callback:function ($$v) {_vm.$set(item, "sel", $$v)},expression:"item.sel"}}):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.rechazado)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red dark-red","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.motivo_rechazo))])])],1):_vm._e(),_c('td',{class:(item.rechazado ? 'red--text' : '') ||
              (item.monto_truncado !== 0 ? 'blue--text' : '')},[_vm._v(" "+_vm._s(item.tipo_comprobante === 0 ? '' : _vm.listTipoFactura.filter( function (tp) { return tp.value === item.tipo_comprobante; } )[0].text)+" ")]),_c('td',{class:(item.rechazado ? 'red--text' : '') ||
              (item.monto_truncado !== 0 ? 'blue--text' : '')},[_vm._v(" "+_vm._s(item.nro_comprobante)+" ")]),_c('td',{class:(item.rechazado ? 'red--text' : '') ||
              (item.monto_truncado !== 0 && item.concepto !== 'TOTALES'
                ? 'blue--text'
                : '')},[_vm._v(" "+_vm._s(item.concepto)+" ")]),_c('td',{class:(item.rechazado ? 'red--text' : '') ||
              (item.monto_truncado !== 0 && item.concepto !== 'TOTALES'
                ? 'blue--text'
                : '')},[_vm._v(" $ "+_vm._s(item.isNotaCred ? '-' : '')+" "+_vm._s(_vm.formatMonto(item.neto_iva))+" ")]),_c('td',{class:(item.rechazado ? 'red--text' : '') ||
              (item.monto_truncado !== 0 && item.concepto !== 'TOTALES'
                ? 'blue--text'
                : '')},[_vm._v(" $ "+_vm._s(item.isNotaCred ? '-' : '')+" "+_vm._s(_vm.formatMonto(item.total))+" ")]),_c('td',[(item.tipo_comprobante)?_c('v-btn',{attrs:{"disabled":item.nombre === '',"small":"","color":"success"},on:{"click":function($event){return _vm.verDoc(item.nombre)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e(),(!_vm.verFlag && item.tipo_comprobante)?_c('v-btn',{attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.eliminar(
                  item,
                  item.id,
                  item.tipo_comprobante,
                  item.nro_comprobante
                )}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(item.rechazado)?_c('v-btn',{attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.revalidar(
                  item.id,
                  item.tipo_comprobante,
                  item.nro_comprobante
                )}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e()],1)])})],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }